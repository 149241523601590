<template>
  <div>

    <v-menu
     max-height="300" 
    transition="slide-y-transition" 
    bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
        class="ml-2" 
        rounded 
        outlined 
        dark 
        v-on="on" 
        v-bind="attrs"
        >
          Atualizar vendedor
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          @click="openConfirmation('vendedor', item.id)"
          v-for="(item, i) in sellerItems"
          :key="i"
          link
        >
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu 
    max-height="300" 
    transition="slide-y-transition" 
    bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
        class="ml-2" 
        rounded 
        outlined 
        dark 
        v-on="on" 
        v-bind="attrs"
        >
          Atualizar status
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          @click="openConfirmation('status', item.value)"
          v-for="(item, i) in statusItems"
          :key="i"
          link
        >
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn class="ml-2 white primary--text" 
    rounded 
    @click="openDeleteConfirmation"
    >
      Excluir
      <v-icon right>fas fa-trash</v-icon>
    </v-btn> 
    <v-dialog 
      v-model="confirmationDialog"
      max-width="400"
      >
      <v-card>
        <v-card-title class="headline">{{ confirmationTitle }}</v-card-title>
        <v-card-text>{{ confirmationText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#D32F2F" text @click="confirmationDialog = false">Cancelar</v-btn>
          <v-btn color="#424242" text @click="confirmAction">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    selectedItems: Array,
  },
  data: () => ({
    statusItems: [
      { text: 'Ativar', value: true },
      { text: 'Desativar', value: false },
    ],
    sellerItems: [],
      confirmationDialog: false, 
      confirmationTitle: '',
      confirmationText: '',
      actionType: '', 
      sellerID: null, 
  }),
  mounted() {
    this.fetchSellers()
  },

  methods: {
    openConfirmation(type, value) {
      this.actionType = type;
      this.sellerID = value;

      if (type === 'vendedor') {
        this.confirmationTitle = 'Confirmar ação';
        this.confirmationText = `Deseja confirmar alteração de vendedores?`;
      } else if (type === 'status') {
        this.confirmationTitle = 'Confirmar ação';
        this.confirmationText = `Deseja confirmar alteração status?`;
        
      }
      
      this.confirmationDialog = true;
    },
    async fetchSellers() {
      const response = await this.$api.get('/people/type/sellers')  
      console.log('fetchSellers', response.data)   
      this.sellerItems = response.data.data   
    },

    openDeleteConfirmation() {
      this.confirmationTitle = 'Confirmar Exclusão';
      this.confirmationText = `Deseja mesmo excluir ${this.selectedItems.length} clientes?`;
      this.actionType = 'delete';
      this.confirmationDialog = true;
    },

    confirmAction() {
      this.confirmationDialog = false;
      
      if (this.actionType === 'vendedor') {
        this.updateMultipleSellers(this.sellerID);
      } else if (this.actionType === 'status') {
        this.updateMultipleStatus(this.sellerID);
      } else if (this.actionType === 'delete') {
        this.deleteMultiple();
      }
    },

    updateMultipleSellers(value) {
      const mappedSelectedItems = this.selectedItems.map((item) => ({
        id: item.id,
        seller_id: value
      }));
      console.log('mappedSelectedItems:', mappedSelectedItems);
      this.$api.post('people/seller/mass', mappedSelectedItems)
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Vendedor setado com sucesso',
            confirm: () => {
              this.$store.commit('setModal', { 
                show: false 
              });
              this.$emit('modalClicked');
              this.$emit('clearSelected');
              window.location.reload();
            },
          });
      })
    },

    updateMultipleStatus(value) {
      this.updateStatus(value);
    },

    updateStatus(value) {
      let selectedIds = this.selectedItems.map((item) => item.id);
      this.$api
        .post(`people/status/mass`, { 
          selectedIds, value 
        })
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Status alterado com sucesso',
            confirm: () => {
              this.$store.commit('setModal', { 
                show: false 
              });
              this.$emit('modalClicked');
              window.location.reload();
            },
          });
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar alterar status',
            confirm: () => {
              this.$store.commit('setModal', { 
                show: false 
              });
            },
          });
        });
    },

    deleteMultiple() {
      let selectedClientsIds = this.selectedItems.map((item) => item.id);
      this.$store.commit('setModal', {
        show: true,
        component: 'warning',
        text: 'Atenção!',
        caption: `Deseja mesmo deletar ${this.selectedItems.length} clientes?`,
        confirm: () => {
          this.$api
            .post('people/massdelete', selectedClientsIds)
            .then(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'success',
                text: 'Clientes excluídos com sucesso',
                confirm: () => {
                  this.$store.commit('setModal', { 
                    show: false
                   });
                  this.$emit('modalClicked');
                  this.$emit('clearSelected');
                  window.location.reload();
                },
              });
            })
            .catch(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'error',
                text: 'Ops! Falha ao tentar excluir estes clientes',
                confirm: () => {
                  this.$store.commit('setModal', { 
                    show: false 
                  });
                },
              });
            });
        },
        negate: () => {
          this.$store.commit('setModal', { 
            show: false 
          });
        },
      });
    },
  },
};
</script>
